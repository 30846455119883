<template>
  <div>
    <h4 class="text-dark-darken">{{ title }}</h4>
    <div class="order-forms mt-4">
      <div role="tab-list" class="border-bottom mb-4 pb-2">
        <div class="d-flex justify-content-baseline cursor-pointer mb-3" @click="toggleFristStep">
          <map-marker color="var(--dark-info)" :orderNumber="1" class="marker-address" />
          <div class="d-flex flex-column ml-2">
            <span class="text-dark-darken font-weight-bold">{{ $t('spot.withdrawal') }}</span>
            <span class="text-transition mt-1">{{ $t('spot.sourceAddress') }}</span>
          </div>
        </div>
        <b-collapse :visible="step === 1" id="form-1" accordion="address" role="tabpanel">
          <address-form :data="sourceData" @address-changed="setSourceAddress" />
        </b-collapse>
      </div>
      <div role="tab-list" class="pb-3">
        <!-- <div class="d-flex justify-content-baseline cursor-pointer mb-3" v-b-toggle.form-2> -->
        <div class="d-flex justify-content-baseline mb-3 cursor-pointer" @click="toggleSecondStep">
          <map-marker color="var(--dark-info)" :orderNumber="2" class="marker-address" />
          <div class="d-flex flex-column ml-2">
            <span class="text-dark-darken font-weight-bold">{{ $t('spot.delivery') }}</span>
            <span class="text-transition mt-1">{{ $t('spot.sourceAddress') }}</span>
          </div>
        </div>
        <b-collapse :visible="step === 2" id="form-2" accordion="address" role="tabpanel">
          <address-form :data="destinationData" @address-changed="setDestinationAddress" isLast />
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'first-step',
  props: {
    title: {
      type: String,
      required: true,
    },
    editInfo: {
      type: Number,
      default: () => null,
    },
    sourceData: {
      type: Object,
      required: true,
    },
    destinationData: {
      type: Object,
      required: true,
    },
  },
  components: {
    AddressForm: () => import('./AddressForm.vue'),
    MapMarker: () => import('../u-map/MapMarker.vue'),
  },
  data() {
    return {
      step: 0,
      source: null,
      destination: null,
    };
  },
  mounted() {
    if (this.editInfo) {
      this.step = this.editInfo;
    }
  },
  watch: {
    editInfo(value) {
      if (value) {
        this.step = value;
      }
    },
  },
  methods: {
    setSourceAddress(data) {
      this.$emit('source', data);
      this.source = data;
      this.step = 2;
    },
    setDestinationAddress(data) {
      if (this.isDiferenteAddress(data)) {
        this.$emit('destination', data);
        this.destination = data;
        this.step = 0;
      } else {
        this.$toast.error(this.$t('messages.addressesAreEqual'));
      }

      if (this.source !== null) {
        this.$emit('next-step', {
          source: this.source,
          destination: this.destination,
        });
      }
    },
    isDiferenteAddress(data) {
      return JSON.stringify(this.source) !== JSON.stringify(data);
    },
    toggleFristStep() {
      if (this.step !== 1) {
        this.step = 1;
      } else {
        this.step = 0;
      }
    },
    toggleSecondStep() {
      if (this.step !== 2) {
        this.step = 2;
      } else {
        this.step = 0;
      }
    },
  },
};
</script>

<style lang="scss">
[role='tab-list'].border-bottom {
  border-width: 2px !important;
  border-color: var(--transition) !important;
}

.marker-address {
  min-width: 28px;
}
</style>
